/*
 * @Author: lazyzzd
 * @Date: 2022-06-29 22:50:59
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-06-30 10:33:52
 * @Description: 工具函数
 */
import JSencrypt from "jsencrypt";
import { getKey } from "@/api/user";

export const encryptRsa = async (str: string): Promise<string> => {
  const key = (await getKey()).t;
  const encrypt = new JSencrypt();
  encrypt.setPublicKey(key);
  return encrypt.encrypt(str).toString();
};

export const nullValue = <T>(val: T, replace = "--"): T | typeof replace => {
  if (!val && (typeof val !== "number" || val !== 0)) {
    return replace;
  }
  return val;
};
