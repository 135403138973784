/*
 * @Author: lazyzzd
 * @Date: 2022-06-28 20:43:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-06-30 09:06:53
 * @Description: 用户接口
 */
import { IKey, IResponse, IUser, IUserNoPassword } from "@/interface";
import request from "../utils/request";

/**
 * 获取公钥
 * @returns IResponse
 */
export const getKey = (() => {
  let key = "";
  return async (): Promise<IKey> => {
    if (key) {
      return { t: key };
    }
    const { data } = await request.post("/api/user/key");
    data && (key = data.t);
    return data;
  };
})();

/**
 * 登录
 * @param data 账号信息
 * @returns IResponse
 */
export const login = (
  data: Pick<IUser, "name" | "password">
): Promise<IResponse<IKey | undefined>> =>
  request.post("/api/user/login", data);

export const getUserInfo = (): Promise<Pick<IUser, "name" | "role">> =>
  request.get("/api/user/info");

/**
 * 新增用户
 * @param data 用户信息
 * @returns IResponse
 */
export const addUser = (data: IUser): Promise<IResponse<undefined>> =>
  request.post("/api/user/add", data);

/**
 * 修改用户信息
 * @param _id 修改的用户id
 * @param data 修改的用户信息
 * @returns IResponse
 */
export const updateUser = (
  _id: string,
  data: Partial<IUser>
): Promise<IResponse<undefined>> =>
  request.post("/api/user/update", { _id, data: { ...data, _id: undefined } });

/**
 * 删除用户
 * @param _id 删除的用户id
 * @returns IResponse
 */
export const deleteUser = (_id: string): Promise<IResponse<undefined>> =>
  request.post("/api/user/delete", { _id });

/**
 * 获取用户列表
 * @returns 用户列表
 */
export const getUsers = (): Promise<IUserNoPassword[]> =>
  request.get("/api/user/list");
